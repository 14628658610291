import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Link } from 'gatsby'

const Caret = loadable(() => import('/src/components/Basic/Caret'))
const BreadcrumbContainer = loadable(() =>
    import('/src/components/Structure/Breadcrumb/BreadcrumbContainer')
)

const Breadcrumb = ({ size, pageTitle, pageParent }) => {
    const firstLevel = pageParent?.node
    const secondLevel = pageParent.node.wpParent?.node

    return (
        <BreadcrumbContainer size={size}>
            {secondLevel && (
                <>
                    <Link to={secondLevel.uri}>{secondLevel.title}</Link>
                    <Caret variant={'forward'} />
                </>
            )}

            {firstLevel && (
                <>
                    <Link to={firstLevel.uri}>{firstLevel.title}</Link>
                    <Caret variant={'forward'} />
                    {pageTitle}
                </>
            )}
        </BreadcrumbContainer>
    )
}

Breadcrumb.propTypes = {
    size: PropTypes.oneOf(['standard', 'narrow']),
    pageTitle: PropTypes.string,
    pageParent: PropTypes.shape({
        node: PropTypes.shape({
            title: PropTypes.string,
            uri: PropTypes.string,
            wpParent: PropTypes.shape({
                node: PropTypes.shape({
                    title: PropTypes.string,
                    uri: PropTypes.string
                })
            })
        })
    })
}

Breadcrumb.defaultProps = {
    size: 'standard',
    pageTitle: 'Page Title',
    pageParent: {
        node: {
            title: 'Page Name',
            uri: '/page-url/'
        }
    }
}

export default Breadcrumb
